<template>
  <transition name="fade">
    <div
      class="popup-map"
      @click.self="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <div id="map">
        <div class="popup-map__form">
          <div class="popup-map__form__title">
            {{ $t("popupMap.label") }}
          </div>
          <Field
            class="popup-map__form__field"
            :value="point.name"
            :placeholder="$t('popupMap.locationName')"
            @field-input="setPointName($event)"
          >
            <template v-slot:error-helper>
              <div v-if="$v.point.name.$error">
                <span v-if="!$v.point.name.required">{{
                  $t("validation.required")
                }}</span>
                <span v-if="$v.point.name.required && !$v.point.name.uniq">
                  {{$t("popupMap.uniqCheckMessage")}}
                </span>
              </div>
            </template>
          </Field>

          <Field
            class="popup-map__form__field"
            :value="getPointCoordinates"
            :placeholder="$t('popupMap.coordinates')"
            :isReadOnly="true"
          >
            <template v-slot:error-helper>
              <div v-if="$v.point.geometry.$error">
                <span v-if="!$v.point.geometry.required">{{
                  $t("validation.required")
                }}</span>
              </div>
            </template>
          </Field>
          <div class="popup-map__form__buttons">
            <Btn
              tag="button"
              btn-type="button"
              bg-color="bg-green-1"
              color="color-neutral-100"
              class="popup-map__form__buttons__button"
              @btn-click="save"
            >
              <template v-slot:content>
                <span class="popup-btn-text btn-text">{{
                  $t("popup.cancel.actions.save")
                }}</span>
              </template>
            </Btn>
            <Btn
              tag="button"
              btn-type="button"
              outline="outline teal-300"
              color="color-teal-300"
              class="popup-map__form__buttons__button"
              @btn-click="$emit('close')"
            >
              <template v-slot:content>
                <span class="popup-btn-text">{{
                  $t("popup.cancel.actions.cancel")
                }}</span>
              </template>
            </Btn>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Field from "@/components/atoms/field/index.vue";
import Btn from "@/components/atoms/btn/index.vue";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
import { axiosLocations } from "@/services/scopes/axios-locations";

export default {
  components: {
    Field,
    Btn,
  },
  data() {
    return {
      map: null,
      draw: null,
      point: {
        name: "",
        place_name: "",
        geometry: {},
      },
      marker: null,
      isUniqName: false,
    };
  },
   validations() {
    return {
      point: {
        name: {
          required,
          uniq: () => {
            return this.isUniqName
          }
        },
        geometry: {
          required,
        },
      }
    }
  },
  methods: {
    ...mapMutations({
      addPoint: "popupMap/addPoint",
    }),
    setPointName(name) {
      this.point.name = name;
      this.point.place_name = name;
      this.$v.point.name.$reset();
    },
    async save() {
      await this.checkUniqName();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit("save", this.point);
        this.addPoint(this.point);
      }
    },
    updatePoint(e) {
      if (e.features.length) {
        const timestamp = +new Date();
        const rand = +Math.floor(Math.random() * 222);
        const id = "custom." + timestamp + rand;

        this.point.id = id;
        this.point.geometry = e.features[0].geometry;
        this.draw.deleteAll();
        this.marker && this.marker.remove();
        this.marker = new mapboxgl.Marker()
          .setLngLat(this.point.geometry.coordinates)
          .addTo(this.map);
      }
    },
    createMarker(e) {
      if (e.lngLat) {
        let coordinates = e.lngLat;

        const timestamp = +new Date();
        const rand = +Math.floor(Math.random() * 222);
        const id = "custom." + timestamp + rand;

        this.point.id = id;
        this.point.geometry = {
          coordinates: [coordinates.lng, coordinates.lat],
          type: "Point"
        };

        this.draw.deleteAll();
        this.marker && this.marker.remove();
        this.marker = new mapboxgl.Marker()
          .setLngLat(this.point.geometry.coordinates)
          .addTo(this.map);

      }
    },
    async checkUniqName() {
      this.isUniqName = false
      if (this.point.name) {
         await axiosLocations.getStaticLocations(this.point.name).then(({data}) => {
           this.isUniqName = !data.data.total;
        });
      }
    }
  },
  computed: {
    getPointCoordinates() {
      return this.point.geometry.coordinates;
    },
  },
  mounted() {
    mapboxgl.accessToken = fakeWindow.mapBoxKey;
     // "pk.eyJ1IjoieXRtZSIsImEiOiJjbGdjNDlvODgwMGtkM2tsNnU5aGlyOXp6In0.uzf2uJq71nTLPf-jvXqrsQ";
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/ytme-app/cljzo3n2k009301pkffhrgkgr",
      center: [-74.5, 40],
      zoom: 9,
    });

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: true,
      },
    });

    this.geocoder = new fakeWindow.MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
      language: this.$i18n.locale + '-' + this.$i18n.locale.toUpperCase(),
      placeholder: this.$t('popupMap.searchLabel'),
    });

    this.map.addControl(this.geocoder);

    this.map.addControl(this.draw);
    this.map.on("draw.create", this.updatePoint);
    if('ontouchend' in document.documentElement) {
      let dragTrigger = false;
      this.map.on("touchmove", () => {
        dragTrigger = true;
      });
      this.map.on("touchend", (e) => {
        if (!dragTrigger) {
          this.createMarker(e);
        }
        dragTrigger = false;
      });
    }
    else{
      this.map.on("click", this.createMarker);
    }
  },
};
</script>

<style lang="scss" scoped>
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 90%;
  height: 90%;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 1000;
  border-radius: 8px;
  border: 1px solid #c1cede;
}

.popup-map {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);

  &__form {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 1000;
    width: 25em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding: 1em;
    background-color: rgba(255, 255, 255, 0.9);

    & > div {
      width: 100%;
    }

    &__title {
      text-align: center;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 4px;
    }

    &__field {
      margin: 1em 1em 0 1em;
    }

    &__buttons {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;
      padding-top: 1em;

      &__button {
        padding: 12px!important;
        margin: 2px;
        margin-bottom: 16px!important;
        flex-grow: 1;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
